import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DataService } from './data.service';
import { Store } from "@ngrx/store";
import * as fromRoot from '../store/reducers';
import * as fromConfig from "../store/actions/config";
import { SiteLinks, State } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private http: DataService, private store: Store<fromRoot.State>) { }

  public load(clinic: string): Observable<any> {
    return this.http.get(`/public/auth/${clinic}`);
  }

  public getFormOptions(): Observable<any> {
    return this.http.get('/public/formoptions');
  }

  public getClinicDetails(): Observable<any> {
    return this.http.get('/public/clinics');
  }

  public getDiarySettings(currentClinicIdx: number): Observable<any> {
    return this.http.get(`/public/diary/settings/${currentClinicIdx}`);
  }

  public loadConfig(clinicName: string, patientIdx: any = null): void {
    this.store.dispatch(fromConfig.LoadConfig({ payload: { clinicName, patientIdx } }));
  }

  public setConfig(config: State): void {
    console.log("[config] set=", config);
    this.store.dispatch(fromConfig.SetConfig({ payload: config }));
  }

  public getJWT(): string {
    let token: string = "";
    this.store.select(fromRoot.getJWT).pipe(take(1)).subscribe((jwt: string) => token = jwt);
    return token;
  }

  public setJWT(token: string): void {
    this.store.dispatch(fromConfig.SetJWT({ payload: token }));
  }

  public setLinks(links: SiteLinks): void{
    this.store.dispatch(fromConfig.SetLinks({ payload: links }));
  }
  public setClinicName(clinic: string): void {
    console.log("[clinic] name",clinic);
    this.store.dispatch(fromConfig.SetClinicName({ payload: clinic }));
  }

  public getStoredDiarySettings(): Observable<any> {
    return this.store.select(fromRoot.getDiarySettings);
  }

  public getStoredAvailabilities(): Observable<any> {
    return this.store.select(fromRoot.getAvailabilities);
  }

  public getStoredClinicDetails(): Observable<any> {
    return this.store.select(fromRoot.getClinicDetails);
  }

  public getClinicName(): Observable<string|undefined>{
    return this.store.select(fromRoot.getClinicName);
  }

  public approvalRequired(): Observable<boolean>{
    return this.store.select(fromRoot.getApprovalRequired).pipe(filter((data): data is boolean => !!data));;
  }

  public getSiteConfig(): Observable<SiteLinks>{
    return this.store.select(fromRoot.getSiteConfig).pipe(filter((data): data is SiteLinks => !!data));
  }
}