<div class="container">
@if (appointment){

    <app-appointment-info [appointment]="appointment"></app-appointment-info>


    
    <div class="col-sm-8 flex-on-desktop" *ngIf="appointment">
        <div class="d-flex my-1">
                <mat-icon>health_and_safety</mat-icon>
                <div class="me-3 w-80">
                    Treatment
                </div>
                <div>
                    {{ appointment.treatmentType }}
                </div>
            
        </div>

        <div class="d-flex my-1">
            <mat-icon>credit_card</mat-icon>
            <div class="me-3 w-80">Price</div>
            <div>
                {{ appointment.price | currency : 'GBP'}}
            </div>
        </div>

        <div class="d-flex my-1">
            <mat-icon>event</mat-icon>
            <div class="me-3 w-80">
                Time
            </div>
            <div>
                {{ appointment.startTime | date : "EEEE, MMMM d, y h:mm a" }}
            </div>
        </div>

        <div class="d-flex my-1">
            <mat-icon>alarm</mat-icon>
            <div class="me-3 w-80">
                Duration
            </div>
            <div>
                {{ appointment.duration }} minutes
            </div>
        </div>
    </div>


    <div>
        
        <button mat-raised-button color="primary" (click)="downloadAppointment()">
            <span class="material-icons">event</span>&nbsp;Download ICS
        </button>
            <a mat-button>Book again</a>

    </div>
}
</div>

